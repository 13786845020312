import axios from 'axios';
import Auth from './Auth';
import Currency from './Currency';
import Customer from './Customer';
import Event from './Event';
import Network from './Network';
import Order from './Order';
import Payer from './Payer';
import PixCashIns from './PixCashIns';
import Tier from './Tier';
import Configuration from './Configuration';
import User from './User';
import Wallet from './Wallet';
import Withdrawal from './Withdrawal';
import Contract from './Contract';
import OtcOrder from './OtcOrder';
import OtcPayment from './OtcPayment';

class MainApi {
  axios;
  auth;
  onUnauthenticatedCallback;

  constructor(props) {
    this.axios = axios.create(props);
    this.token = '';
    this.auth = new Auth(this);
    this.currency = new Currency(this);
    this.customer = new Customer(this);
    this.event = new Event(this);
    this.network = new Network(this);
    this.order = new Order(this);
    this.payer = new Payer(this);
    this.pixCashIn = new PixCashIns(this);
    this.tier = new Tier(this);
    this.configuration = new Configuration(this);
    this.user = new User(this);
    this.wallet = new Wallet(this);
    this.withdrawal = new Withdrawal(this);
    this.contract = new Contract(this);
    this.otcOrder = new OtcOrder(this);
    this.otcPayment = new OtcPayment(this);
    this.setupInterceptors();
  }

  setupInterceptors() {
    this.axios.interceptors.request.use(
      async (config) => {
        const csrfToken = this.getCookie('XSRF-TOKEN');
        if (csrfToken) {
          config.headers['X-XSRF-TOKEN'] = csrfToken;
        }
        return config;
      },
      (error) => Promise.reject(error),
    );

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          this.onUnauthenticated(error.response);
        }
        if (error?.response?.status === 419) {
          this.onUnauthenticated(error.response);
        }
        if (error?.response?.data?.code === 403) {
          this.onUnauthenticated(error.response.data);
        }
        return Promise.reject(error);
      },
    );
  }

  getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return '';
  }

  onUnauthenticated(callback) {
    this.onUnauthenticatedCallback = callback;
  }
}

export default MainApi;
