import BaseModule from './BaseModule.js';

class OtcPayment extends BaseModule {
  async paginate({ page, per_page, term, status, customer_id }) {
    return await this.get(`back-office/otc/payments`, {
      params: { page, per_page, term, status, customer_id },
    });
  }

  async fetch(id) {
    return await this.get(`back-office/otc/payments/${id}`);
  }

  async refund(id, reason) {
    return await this.post(`back-office/otc/payments/${id}/refund`, {
      reason,
    });
  }
}

export default OtcPayment;
