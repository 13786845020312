import BaseModule from './BaseModule.js';

class Wallet extends BaseModule {
  async paginate({ page, per_page }) {
    return await this.get(`/back-office/wallets`, { params: { page, per_page } });
  }

  async fetch(id) {
    return await this.get(`/back-office/wallets/${id}`);
  }

  async update({ id, status, name }) {
    return await this.patch(`/back-office/wallets/${id}`, {
      status: status,
      name: name,
    });
  }
}

export default Wallet;
