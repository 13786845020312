import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { cifBr, cifUs } from '@coreui/icons'; //cifDe, cifEs, cifFr,
import { setUi } from 'src/reducers/LayoutReducer';
import { useTranslation } from 'react-i18next';

const LanguageDropdown = () => {
  const headerRef = useRef();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const stateUI = useSelector((state) => state.ui.ui);
  const { language } = stateUI;

  useEffect(() => {
    document.addEventListener('scroll', () => {
      headerRef.current &&
        headerRef.current.classList.toggle('shadow-sm', document.documentElement.scrollTop > 0);
    });
  }, []);

  const languages = [
    { code: 'en', icon: cifUs, label: 'language.en' },
    { code: 'pt_br', icon: cifBr, label: 'language.pt_br' },
    // { code: 'es', icon: cifEs, label: 'language.es' },
    // { code: 'de', icon: cifDe, label: 'language.de' },
    // { code: 'fr', icon: cifFr, label: 'language.fr' },
  ];

  const handleLanguage = (selected) => {
    i18n.changeLanguage(selected);
    i18n.reloadResources(selected);
    dispatch(setUi({ ...stateUI, language: selected }));
  };

  return (
    <CDropdown dark component="li" variant="nav-item">
      <CDropdownToggle caret={false}>
        <CIcon icon={languages.find((lang) => lang.code === language)?.icon || 'cifUs'} size="lg" />
      </CDropdownToggle>
      <CDropdownMenu>
        {languages.map((lang) => (
          <React.Fragment key={lang.code}>
            <CDropdownItem
              active={language === lang.code}
              className="d-flex align-items-center"
              component="button"
              type="button"
              onClick={() => handleLanguage(lang.code)}
            >
              <CIcon className="me-2" icon={lang.icon} size="lg" /> {t(lang.label)}
            </CDropdownItem>
            <CDropdownDivider />
          </React.Fragment>
        ))}
      </CDropdownMenu>
    </CDropdown>
  );
};

export default LanguageDropdown;
